import { useTranslation } from 'react-i18next';
import type { MarketPartyRegistrationDto } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { DescriptionListItem } from '#pie/components/description-list/description-list-item/DescriptionListItem';
import { DescriptionList } from '#pie/components/description-list/DescriptionList';
import { InfoTooltip } from '#pie/components/info-tooltip/InfoTooltip';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';

interface MarketPartyCompanyProps {
  marketParty: MarketPartyRegistrationDto;
}

const hasAddress = (marketParty: MarketPartyRegistrationDto) =>
  marketParty.streetName && marketParty.houseNumber && marketParty.zipCode && marketParty.city && marketParty.country;

export const MarketPartyCompany = ({ marketParty }: MarketPartyCompanyProps) => {
  const { t } = useTranslation('marketParty');

  return (
    <Card
      size="md"
      heading={
        <Stack direction="row" gap="sm" align="center">
          <Text as="h2" variant="h6" className="text-primary-dark">
            {t('company.title')}
          </Text>
          <InfoTooltip className="text-primary-dark" aria-label="marketparty information is from the kvk" size={20}>
            {t('market_party_company.tooltip')}
          </InfoTooltip>
        </Stack>
      }
      shadow={true}
    >
      <DescriptionList variant="grid">
        <DescriptionListItem title={t('company.statutory_name')} direction="vertical">
          {marketParty.statutoryName}
        </DescriptionListItem>
        <DescriptionListItem title={t('company.kvk')} direction="vertical">
          {marketParty.chamberOfCommerceNumber}
        </DescriptionListItem>
        {hasAddress(marketParty) && (
          <DescriptionListItem title={t('company.address')} direction="vertical">
            <span>
              {marketParty.streetName} {marketParty.houseNumber}
            </span>
            <br />
            <span>
              {marketParty.zipCode} {marketParty.city}
            </span>
            <br />
            <span>{marketParty.country}</span>
          </DescriptionListItem>
        )}
      </DescriptionList>
    </Card>
  );
};
