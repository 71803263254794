import { useTranslation } from 'react-i18next';
import { AuthorizationRoles } from '#edsn/api/pie-bff';
import { StatusTag } from '../status-tag/StatusTag';
import type { ComponentProps } from 'react';

export interface StatusTagProps {
  className?: string;
  role: AuthorizationRoles;
}

const AuthMap: Record<AuthorizationRoles, ComponentProps<typeof StatusTag>['status']> = {
  [AuthorizationRoles.Admin]: 'attention',
  [AuthorizationRoles.CprAdmin]: 'update',
  [AuthorizationRoles.CprRead]: 'update',
  [AuthorizationRoles.Tickets]: 'progress',
  [AuthorizationRoles.KnowledgeArticles]: 'new',
  [AuthorizationRoles.DataRequest]: 'completed',
  [AuthorizationRoles.SuperUser]: 'completed',
  [AuthorizationRoles.OrganisationAdmin]: 'attention',
};

export function AuthorizationTag({ className, role }: StatusTagProps) {
  const { t } = useTranslation();
  return (
    <StatusTag status={AuthMap[role]} className={className}>
      {t(`common.roles.${role}`)}
    </StatusTag>
  );
}
