import boltIcon from '@iconify/icons-material-symbols/bolt-outline';
import gasIcon from '@iconify/icons-material-symbols/local-fire-department-outline';

export const getProductIconFromText = (text?: string) => {
  if (typeof text !== 'string') {
    return null;
  }

  const lowerText = text.toLowerCase();
  const hasElectricity = lowerText.includes('_23');
  const hasGas = lowerText.includes('_27');

  if (hasElectricity && !hasGas) return boltIcon;
  if (hasGas && !hasElectricity) return gasIcon;
};
