import { useTranslation } from 'react-i18next';
import type { MarketPartyRegistrationDto } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { DescriptionListItem } from '#pie/components/description-list/description-list-item/DescriptionListItem';
import { DescriptionList } from '#pie/components/description-list/DescriptionList';
import { Icon } from '#pie/components/icon/Icon';
import { InfoTooltip } from '#pie/components/info-tooltip/InfoTooltip';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { getFormattedDate } from '#pie/i18n/dateFormats';
import { getProductIconFromText } from '#pie/utils/textToIcon';

interface MarketRoleAuthorizationProps {
  marketParty: MarketPartyRegistrationDto;
}

export const MarketRoleAuthorization = ({ marketParty }: MarketRoleAuthorizationProps) => {
  const { t } = useTranslation('marketParty');

  return (
    <Card
      size="lg"
      heading={
        <Stack direction="row" gap="sm" align="center">
          <Text as="h2" variant="h6" className="text-primary-dark">
            {t('authorization.title')}
          </Text>
          <InfoTooltip className="text-primary-dark" aria-label="authorization tooltip" size={20}>
            {t('authorization.title.tooltip')}
          </InfoTooltip>
        </Stack>
      }
      headingUnderline={false}
    >
      {marketParty.licenses?.map((license, index) => (
        <Stack gap="sm" className="mb-4">
          <Card
            key={index}
            size="md"
            heading={
              <Stack direction="row" gap="xs" align="center">
                {/* v8 ignore next */}
                {license.name && getProductIconFromText(license.name) && (
                  <Icon icon={getProductIconFromText(license.name)!} size={24} className="text-primary-dark" />
                )}
                <Text as="h2" variant="base" className="text-primary-dark font-bold">
                  {license.name}
                </Text>
              </Stack>
            }
            shadow={false}
          >
            <DescriptionList variant="grid">
              <DescriptionListItem direction="vertical" title={t('authorization.service')}>
                {license.providedService}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('startdate')}>
                {license.startDate && getFormattedDate(new Date(license.startDate), false)}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('enddate')}>
                {(license.endDate && getFormattedDate(new Date(license.endDate), false)) || 'Onbepaald'}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('authorization.reference')}>
                {license.referenceDescription}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('authorization.assignment_authority')}>
                {license.assigmentAuthorityName}
              </DescriptionListItem>
            </DescriptionList>
          </Card>
        </Stack>
      ))}
    </Card>
  );
};
