import type { MarketPartyRegistrationDto } from '../generated/model';

export const createMarketPartyInformation = (
  overrides: Partial<MarketPartyRegistrationDto> = {}
): MarketPartyRegistrationDto => ({
  // chamberOfCommerceNumber: '12345678',
  // city: 'Amsterdam',
  // country: 'NL',
  // houseNumber: 123,
  // licenses: [
  //   {
  //     assigmentAuthorityName: 'ACM',
  //     endDate: 'Onbepaald',
  //     name: 'Leveringsvergunning Elektriciteit',
  //     providedService: 'LVR',
  //     referenceDescription: 'ACM/UIT/583999',
  //     startDate: '01 januari 2020',
  //   },
  //   {
  //     assigmentAuthorityName: 'GTS',
  //     endDate: '31 december 2028',
  //     name: 'Leveringsvergunning Gas',
  //     providedService: 'LVR',
  //     referenceDescription: 'DVT/UIT/583999',
  //     startDate: '01 juni 2022',
  //   },
  // ],
  // marketParticipantEanCode: '8712423010208',
  // marketParticipantStartDate: '01 januari 2020',
  // marketRole: 'DDQ',
  // preferences: [
  //   {
  //     eanCode: '8712423010208',
  //     endDate: '01 januari 2022',
  //     productName: '_23',
  //     startDate: '01 januari 2020',
  //   },
  //   {
  //     eanCode: '8712423010208',
  //     endDate: '01 januari 2022',
  //     productName: '_27',
  //     startDate: '01 januari 2020',
  //   },
  // ],
  // serviceCategories: [
  //   {
  //     marketSegment: 'SML',
  //     product: '_23',
  //   },
  //   {
  //     marketSegment: 'LRG',
  //     product: '_23',
  //   },
  //   {
  //     product: '_27',
  //   },
  // ],
  // status: 'ACT',
  // statutoryName: 'Energy Trading Company B.V.',
  // streetName: 'Energy Street',
  // subUnits: [
  //   {
  //     eanCode: '8718222345667',
  //     endDate: 'Onbepaald',
  //     iban: 'NL12ABNA0123456789',
  //     name: 'Eneco B.B.',
  //     startDate: '01 januari 2020',
  //     vat: '123456789B00',
  //   },
  //   {
  //     eanCode: '4823396406869',
  //     endDate: 'Onbepaald',
  //     iban: 'NL11ABNA0987654321',
  //     name: 'Eneco B.V. Stroom Nieuw',
  //     startDate: '01 maart 2021',
  //     vat: '987654321B01',
  //   },
  //   {
  //     eanCode: '8707974981756',
  //     endDate: 'Onbepaald',
  //     iban: 'NL10ABNA0135792468',
  //     name: 'Eneco B.V. Gas Nieuw',
  //     startDate: '01 oktober 2025',
  //     vat: '456789123B02',
  //   },
  // ],
  // zipCode: '1234 AB',
  // ...overrides,
});
