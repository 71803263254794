import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type MarketPartyRegistrationDto, getMarketpartyRegistration } from '#edsn/api/pie-bff';
import { MarketPartyCompany } from './MarketPartyCompany';
import { MarketPartyPreferredParties } from './MarketPartyPreferredParties';
import { MarketPartyRegistrationDetails } from './MarketPartyRegistrationDetails';
import { MarketPartySubRegistration } from './MarketPartySubRegistration';
import { MarketRoleAuthorization } from './MarketRoleAuthorization';
import { Page } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';

export const MarketPartyRegistration = () => {
  const { t } = useTranslation('marketParty');
  // const { data, isLoading } = useGetMarketpartyRegistration();
  const [marketParty, setMarketParty] = useState<MarketPartyRegistrationDto | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const marketParty = await getMarketpartyRegistration();
      setMarketParty(marketParty);
    };
    fetchData();
  }, []);

  // const dummyMarketParty: MarketPartyRegistrationDto = {
  //   chamberOfCommerceNumber: '12345678',
  //   city: 'Amsterdam',
  //   country: 'NL',
  //   houseNumber: 123,
  //   licenses: [
  //     {
  //       assigmentAuthorityName: 'ACM',
  //       name: 'Leveringsvergunning _23',
  //       providedService: 'LVR',
  //       referenceDescription: 'ACM/UIT/583999',
  //       startDate: '2022-01-01T00:00:00Z',
  //     },
  //     {
  //       assigmentAuthorityName: 'TNT',
  //       name: 'Leveringsvergunning _27',
  //       providedService: 'LVR',
  //       referenceDescription: 'DVT/UIT/583999',
  //       startDate: '2025-01-02T00:00:00Z',
  //     },
  //   ],
  //   marketParticipantEanCode: '8718222345667',
  //   marketParticipantStartDate: '2025-02-03T00:00:00Z',
  //   marketRole: 'EZ',
  //   preferences: [
  //     {
  //       eanCode: '8712423010208',
  //       productName: '_23',
  //       startDate: '2023-02-03T00:00:00Z',
  //     },
  //     {
  //       eanCode: '8712423010208',
  //       productName: '_27',
  //       startDate: '2022-03-05T00:00:00Z',
  //     },
  //   ],
  //   serviceCategories: [
  //     {
  //       marketSegment: 'SML',
  //       product: '_23',
  //     },
  //     {
  //       product: '_27',
  //     },
  //   ],
  //   status: 'ACT',
  //   statutoryName: 'Energy Trading Company B.V.',
  //   streetName: 'Energy Street',
  //   subUnits: [
  //     {
  //       eanCode: '8718222345667',
  //       endDate: '',
  //       iban: 'NL12ABNA0123456789',
  //       name: 'Eneco B.B.',
  //       startDate: '2022-01-05T00:00:00Z',
  //       vat: '123456789B00',
  //     },
  //     {
  //       eanCode: '4823396406869',
  //       endDate: '',
  //       iban: 'NL11ABNA0987654321',
  //       name: 'Eneco B.V. Stroom Nieuw',
  //       startDate: '2022-01-05T00:00:00Z',
  //       vat: '987654321B01',
  //     },
  //     {
  //       eanCode: '8707974981756',
  //       endDate: '',
  //       iban: 'NL10ABNA0135792468',
  //       name: 'Eneco B.V. Gas Nieuw',
  //       startDate: '2022-01-05T00:00:00Z',
  //       vat: '456789123B02',
  //     },
  //   ],
  //   zipCode: '1234 AB',
  // };

  return (
    <>
      <PageHead title={t('title')} />
      <Page>
        <Stack gap="md" className="mx-auto mb-6 max-w-4xl">
          <Stack gap="md" className="mx-6">
            <Text as="h2" variant="h5">
              {t('title')}
            </Text>
            <Text variant="base">{t('description')}</Text>
          </Stack>
        </Stack>
        <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
          {marketParty && (
            <>
              <MarketPartyCompany marketParty={marketParty} />
              <MarketRoleAuthorization marketParty={marketParty} />
              <MarketPartyRegistrationDetails marketParty={marketParty} />
              <MarketPartyPreferredParties marketParty={marketParty} />
              <MarketPartySubRegistration marketParty={marketParty} />
            </>
          )}
        </Stack>
      </Page>
    </>
  );
};
