import { HttpResponse, http, delay as mswDelay } from 'msw';
import { Product } from '../generated/model';
import { createOrganizationInformation } from './account';
import { createAttachment } from './attachment';
import { createSurveyDialagues } from './ces';
import { createComments } from './comments';
import { createConnectionInformation, createRegionalGridOperators, generateEan } from './connection';
import { createContacts } from './contact';
import { createContactDetails, createContactsDetails } from './contactdetails';
import { createCprContacts, createCprFilters, createCprManagementContacts } from './cpr';
import { createKnowledgeArticleDetail, createKnowledgeArticleListResult } from './knowledgeBase';
import { createMarketPartyInformation } from './marketPartyInformation';
import { createNotification } from './notifications';
import { createSurveyDialogue } from './surveyDialogue';
import { createTeams } from './team';
import { createTeamDetails } from './teamdetails';
import {
  createMainCategories,
  createRejectionCategories,
  createTicket,
  createTicketFilters,
  createTickets,
} from './ticket';
import type { CreateResponseDto, ExecuteMultipleResponseDto } from '../generated/model';

async function delay(duration: number): Promise<void> {
  // Ensure there is no response delay in tests.
  return import.meta.env.MODE === 'test' ? undefined : mswDelay(duration);
}

export const handlers = [
  http.post('*/attachment', () =>
    HttpResponse.json({
      bytes: 1000000,
      id: '123',
      mimeType: 'application/pdf',
      url: '/',
    })
  ),
  http.get('*/attachment/metadata', () =>
    HttpResponse.json({ items: [{ bytes: 12345, fileName: 'file.txt', id: '123' }], totalCount: 1 })
  ),
  http.get('*/comment', async () => {
    await delay(100);
    return HttpResponse.json(createComments());
  }),
  http.post('*/comment/create', () => HttpResponse.json({ id: '123' })),
  http.get('*/connection-information', ({ request }) => {
    const url = new URL(request.url);
    return HttpResponse.json(
      createConnectionInformation({
        ean18: url.searchParams.get('ean18')?.toString(),
        failureEventCode: url.searchParams.get('ean18') === generateEan(18, 'gas') ? '14.1' : undefined, // 808679959269303857
        product: url.searchParams.get('ean18') === generateEan(18, 'gas') ? Product.Gas : Product.Electricity,
      })
    );
  }),
  http.get('*/contact', () => HttpResponse.json(createContacts())),
  http.get('*/grid-operator', async () => {
    await delay(100);
    return HttpResponse.json(createRegionalGridOperators());
  }),
  http.get('*/main-category', async () => {
    await delay(100);
    return HttpResponse.json(createMainCategories());
  }),
  http.get('*/rejection-category', async () => {
    await delay(100);
    return HttpResponse.json(createRejectionCategories());
  }),
  http.get('*/team', async () => {
    await delay(100);
    return HttpResponse.json(createTeams());
  }),
  http.get('*/team/:id', async () => {
    await delay(100);
    return HttpResponse.json(createTeamDetails());
  }),
  http.post('*/ticket', async () => {
    await delay(100);
    return HttpResponse.json(createTickets());
  }),
  http.post('*/attachment', async () => HttpResponse.json(createAttachment())),
  http.post('*/survey/create', async () =>
    HttpResponse.json({ failedIds: [], success: true } satisfies ExecuteMultipleResponseDto)
  ),
  http.post('*/ticket/call-to-action', () => HttpResponse.json({ id: 123 })),
  http.post('*/ticket/export', async () => {
    await delay(100);
    return new HttpResponse('', { headers: { 'Content-Type': 'text/csv' } });
  }),
  http.post('*/ticket/create', async () => {
    await delay(100);
    return HttpResponse.json({ id: '86ce5cdb-03bb-a83c-3363-50b132480f07' });
  }),
  http.get('*/ticket/filters', () => HttpResponse.json(createTicketFilters())),
  http.get('*/ticket/:id', () => HttpResponse.json(createTicket())),
  http.get('*/user/organisation-information', () => HttpResponse.json(createOrganizationInformation())),
  http.post('*/knowledge-article', async () => {
    await delay(100);
    return HttpResponse.json(createKnowledgeArticleListResult());
  }),
  http.get('*/knowledge-article/categories', async () => HttpResponse.json(createMainCategories())),
  http.get('*/knowledge-article/:id', () => HttpResponse.json(createKnowledgeArticleDetail())),
  http.post('*/survey', async () => {
    await delay(100);
    return HttpResponse.json(createSurveyDialagues());
  }),
  http.get('*/survey/:id', () => HttpResponse.json(createSurveyDialogue())),
  http.post('*/cpr-contact', () => HttpResponse.json(createCprContacts())),
  http.post('*/cpr-organisation', () => HttpResponse.json(createCprContacts())),
  http.post('*/cpr-management-contact', () => HttpResponse.json(createCprManagementContacts())),
  http.post('*/cpr-management-contact/contact', () => HttpResponse.json(createContacts())),
  http.post('*/cpr-management-contact/create', () => HttpResponse.json({ id: '1' } satisfies CreateResponseDto)),
  http.post('*/cpr-management-contact/contact/create', () =>
    HttpResponse.json({ id: '1' } satisfies CreateResponseDto)
  ),
  http.put('*/cpr-management-contact/contact/:id', () => HttpResponse.text('')),
  http.post('*/cpr-management-contact/:id', () => HttpResponse.json(createCprManagementContacts())),
  http.get('*/cpr-contact/filters', () => HttpResponse.json(createCprFilters())),
  http.post('*/cpr-contact/export', async () => {
    await delay(100);
    return new HttpResponse('', { headers: { 'Content-Type': 'text/csv' } });
  }),
  http.post('*/cpr-organisation/export', async () => {
    await delay(100);
    return new HttpResponse('', { headers: { 'Content-Type': 'text/csv' } });
  }),
  http.post('*/contact/details', () => HttpResponse.json(createContactsDetails())),
  http.post('*/contact/details/create', () => HttpResponse.json({ id: '1' } satisfies CreateResponseDto)),
  http.put('*/contact/details/:id', () => HttpResponse.json({ id: '1' })),
  http.get('*/contact/details/:id', () => HttpResponse.json(createContactDetails())),
  http.delete('*/contact/details/:id', () => HttpResponse.json({ id: '1' })),
  http.post('*/contact/details/export', async () => {
    await delay(100);
    return new HttpResponse('', { headers: { 'Content-Type': 'text/csv' } });
  }),
  http.get('*/notification', () => HttpResponse.json(createNotification())),
  http.put('*/notification', () => HttpResponse.text('')),
  http.post('*/notification/sub-category', () => HttpResponse.text('')),
  http.delete('*/notification/sub-category/:id', () => HttpResponse.text('')),
  http.post('*/dataRequest/create', () => HttpResponse.json({ id: '1' })),
  http.get('*/marketparty-registration', () => HttpResponse.json(createMarketPartyInformation())),
];
